function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    var items = location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
}

function getGTMParameters(gtmProduct, pageContext) {

    var productCurrencyCode = gtmProduct.find('.gtm-currencyCode').text();
    var productList = '';
    if (pageContext) {
        if (pageContext.ns == 'homepage') {
            productList = 'homepage';
        }
        else if (pageContext.ns == 'search' && findGetParameter('q') != null) {
            productList = 'q='+findGetParameter('q');
        }
        else if ($('.cc-breadcrumb__wrp').length) {
            productList = $('.cc-breadcrumb__wrp').text().replace(/\n/g, "").replace(/ /g, "");
        }
    }
    return {
        'name': gtmProduct.find('.gtm-productName').text(),
        'id': gtmProduct.find('.gtm-productID').text(),
        'price': gtmProduct.find('.gtm-productPrice').text(),
        'sv8': gtmProduct.find('.gtm-productStartPrice').text(),
        'brand': gtmProduct.find('.gtm-productBrand').text(),
        'category': gtmProduct.find('.gtm-productCategory').text(),
        'categoryid': gtmProduct.find('.gtm-productCategoryID').text(),
        'list': productList,
        'position': 1,
        'productCurrencyCode': productCurrencyCode
    }

}

function sendClickGTM(event) {

    if(typeof dataLayer != undefined && window.analyticsWrapper) {

        var gtmProduct = $(event.currentTarget).find('.gtm-product-parms');
        var pageContext = window.pageContext;
        var params = getGTMParameters(gtmProduct, pageContext);

        try{
            window.analyticsWrapper('productClick', {
                'ecommerce': {
                    'currencyCode': params.productCurrencyCode,
                    'click': {
                        'actionField': {'list': params.list},
                        'products': [{
                            'name': params.name,
                            'id': params.id,
                            'price': params.price,
                            'sv8': params.sv8,
                            'brand': params.brand,
                            'category': params.category,
                            'categoryid': params.categoryid,
                            'position': params.position
                        }]
                    }
                }
            }, 'true', 'it');
        }
        catch(error){
            console.error('Error: impossible to send event productClick');
        }
    }
        
}

window.initGTMProductTileImpressions = function () {
    
    if(typeof dataLayer != undefined && $('.gtm-product-parms').length && window.analyticsWrapper) {
    
        var productImpressions = [];
        var currencyCode = '';
        var pageContext = window.pageContext;

        $('.gtm-product-parms').each(function () {
            
            var gtmProduct = $(this);
            if (!gtmProduct.hasClass('gtm-impressed')) {

                var params = getGTMParameters(gtmProduct, pageContext);
                
                productImpressions.push({
                    'name': params.name, // Nome prodotto
                    'id': params.id, //SKU o ID completo del prodotto
                    'price': params.price, //Prezzo di vendita del prodotto
                    'sv8': params.sv8, //Prezzo iniziale di vendita del prodotto senza sconto
                    'brand': params.brand, //Brand del prodotto
                    'category': params.category, // Nome categoria/tipologia associata al prodotto
                    'categoryid': params.categoryid, // ID categoria/tipologia associata al prodotto
                    'list': params.list, // Tipologia di lista in cui l’utente vede il prodotto è necessaria per il tracciamento in GA
                    'position': params.position //è necessaria per il tracciamento in GA
                })

                gtmProduct.addClass('gtm-impressed');
                
                if (currencyCode != params.productCurrencyCode) {
                    currencyCode = params.productCurrencyCode;
                }
            }

        });

        try{
            window.analyticsWrapper('productImpression', {
                'ecommerce': {
                    'currencyCode': currencyCode, // Valuta locale
                    'impressions': productImpressions
                }
            });
        }
        catch(error){
            console.error('Error: impossible to send event productImpression');
        }
    }

}

window.initGTMProductTileClicks = function () {

    $('.product').each(function () {
        var $productTile = $(this);
        if ($productTile.find('.data-itemid').attr('data-itemid') && !$productTile.hasClass('gtm-clicked')) {
            $productTile.on('click', sendClickGTM);
            $productTile.addClass('gtm-clicked');
        }
    });

}

$(document).ready(function() {

    // ProducTiles impression
    window.initGTMProductTileImpressions();
    
    // ProducTiles click
    window.initGTMProductTileClicks();

});